/* global clearInterval, console, setInterval */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getFirestore, getDoc, doc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBE4jvTTaRNKN0iXNd_B_AP4Xrwl4EXqYs",
  authDomain: "dedupeone.firebaseapp.com",
  databaseURL: "https://dedupeone-default-rtdb.firebaseio.com",
  projectId: "dedupeone",
  storageBucket: "dedupeone.appspot.com",
  messagingSenderId: "939637555230",
  appId: "1:939637555230:web:615611b6fa04962e56ae0f",
  measurementId: "G-JDNL35CYD1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

/**
 * Parses human data asynchronously.
 * @customfunction
 * @helpurl https://splitnamesinexcel.com/taskpane.html
 * @param {string[][]} addresses The input data to parse.
 * @returns {Promise<string[][]>} A promise resolving to parsed data.
 */
async function SPLITADDRESS(addresses) {
  if (localStorage.getItem("userEmail")) {
    function todayDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }

    function dateCheck(subscription_ending_date) {
      var today = todayDate();
      var today_date = new Date(today);
      var subscription_end_date = new Date(subscription_ending_date);
      return subscription_end_date.getTime() > today_date.getTime();
    }

    try {
      // Function to check subscription status
      async function checkSubscription() {
        var email = localStorage.getItem("userEmail");
        var emailSubscriptionExist = false;
        var subscriptionNotExpired = false;
        const docRef = doc(db, "payments", "humanparser");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data) {
          const emails = Object.keys(data);
          const dates = Object.values(data);
          for (var i = 0; i < emails.length; i++) {
            if (email == emails[i]) {
              emailSubscriptionExist = true;
              subscriptionNotExpired = dateCheck(dates[i]);
              break;
            }
          }
        }
        return emailSubscriptionExist && subscriptionNotExpired;
      }

      // Check subscription status
      var sub_status = await checkSubscription();

      var tries = parseInt(localStorage.getItem("tries"));
      if (isNaN(tries)) {
        tries = 0;
        localStorage.setItem("tries", tries);
      }

      // Perform the subscription check and process accordingly
      if (sub_status || tries < 7) {
        tries++;
        localStorage.setItem("tries", tries);
        // Convert single address input into an array
        if (!Array.isArray(addresses[0])) {
          addresses = [addresses];
        }

        // Filter out subarrays that contain 0 or an empty string
        const filteredAddresses = addresses.filter(
          (subArray) => !subArray.some((value) => value === "0" || value === "")
        );

        // Construct the query parameter based on the filtered addresses
        const queryParams = filteredAddresses
          .map((address) => `address=${encodeURIComponent(address.join(", "))}`)
          .join("&");

        // Fetch data from the URL
        const response = await fetch(`https://humanparser.vercel.app/parseaddress?${queryParams}`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Static headers
        // const headers = ["Address", "City", "State", "Zip", "Full Address"];

        // Transform the parsed data into an array of objects
        const transformedData = data.parsedAddresses.map((parsedAddress) => {
          return {
            address: parsedAddress.address || "",
            city: parsedAddress.city || "",
            state: parsedAddress.state || "",
            zip: parsedAddress.zip || "",
            fullAddress: parsedAddress.fullAddress || "",
          };
        });

        // Format the transformed data into a two-dimensional array of strings representing key-value pairs
        // const formattedData = transformedData.map((parsedAddress) => {
        //   const addressArray = [];
        //   for (const [key, value] of Object.entries(parsedAddress)) {
        //     addressArray.push(value);
        //   }
        //   return addressArray;
        // });

        const formattedData = transformedData.map((parsedAddress, index) => {
          const addressArray = [];
          for (const [key, value] of Object.entries(parsedAddress)) {
            if (index === 0) {
              // If it's the first array
              addressArray.push(`${key}: ${value}`); // Push key and value as a string
            } else {
              addressArray.push(value); // Push only the value for subsequent arrays
            }
          }
          return addressArray;
        });

        // Construct the result array
        // const result = [headers, ...formattedData];

        const result = formattedData;

        if (sub_status) {
          // Return all values if subscription is active
          return result;
        } else {
          if (result.length < 20) {
            // If user is on free trial and result records are less than 20, return the results without additional messages
            return result;
          } else {
            let sliceR = result.slice(0, 20);
            // If user is on free trial and result records are 20 or more, return top 20 values with additional messages
            sliceR.splice(
              20,
              0,
              ["• Only Top 20 records shown. Please subscribe to enjoy unlimited records lookups", "", "", "", ""],
              ["• To subscribe, click on upgrade button then click on number to words", "", "", "", ""],
              ["• for assistance contact us on smallpdfkenya@gmail.com", "", "", "", ""]
            );
            return sliceR;
          }
        }
      } else {
        // Return appropriate message if subscription is not active
        return [
          ["• Free runs already exhausted"],
          ["• To subscribe, click on upgrade on the side menu"],
          ["• for assistance contact us on smallpdfkenya@gmail.com"],
        ];
      }
    } catch (error) {
      throw error;
    }
  } else {
    return [["• Please login to use formula"]];
  }
}

// Export the function for use elsewhere
export { SPLITADDRESS };

/**
 * Parses human data asynchronously.
 * @customfunction
 * @helpurl https://splitnamesinexcel.com/taskpane.html
 * @param {string[][]} fullNames The input data to parse.
 * @returns {Promise<string[][]>} A promise resolving to parsed data.
 */
async function SPLITNAMES(fullNames) {
  debugger;
  if (localStorage.getItem("userEmail")) {
    function todayDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }

    function dateCheck(subscription_ending_date) {
      var today = todayDate();
      var today_date = new Date(today);
      var subscription_end_date = new Date(subscription_ending_date);
      return subscription_end_date.getTime() > today_date.getTime();
    }

    try {
      // Function to check subscription status
      async function checkSubscription() {
        var email = localStorage.getItem("userEmail");
        var emailSubscriptionExist = false;
        var subscriptionNotExpired = false;
        const docRef = doc(db, "payments", "humanparser");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data) {
          const emails = Object.keys(data);
          const dates = Object.values(data);
          for (var i = 0; i < emails.length; i++) {
            if (email == emails[i]) {
              emailSubscriptionExist = true;
              subscriptionNotExpired = dateCheck(dates[i]);
              break;
            }
          }
        }
        return emailSubscriptionExist && subscriptionNotExpired;
      }

      // Check subscription status
      var sub_status = await checkSubscription();

      var tries = parseInt(localStorage.getItem("tries"));
      if (isNaN(tries)) {
        tries = 0;
        localStorage.setItem("tries", tries);
      }

      // Perform the subscription check and process accordingly
      if (sub_status || tries < 7) {
        tries++;
        localStorage.setItem("tries", tries);
        // Convert single name input into an array
        if (!Array.isArray(fullNames[0])) {
          fullNames = [fullNames];
        }

        // Filter out subarrays that contain 0 or an empty string
        const filteredNames = fullNames.filter((subArray) => !subArray.some((value) => value === "0" || value === ""));

        // Construct the query parameter based on the filtered names
        const queryParams = filteredNames
          .map((nameArray) => `fullName=${encodeURIComponent(nameArray.join(" "))}`)
          .join("&");

        // Fetch data from the API endpoint
        const response = await fetch(`https://humanparser.vercel.app/parsename?${queryParams}`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Static headers
        // const headers = ["Salutation", "First Name", "Suffix", "Last Name", "Middle Name", "Full Name"];

        // Transform the parsed data into an array of objects with specific keys
        const transformedData = data.parsedNames.map((parsedName) => {
          return {
            salutation: parsedName.salutation || "",
            firstName: parsedName.firstName || "",
            suffix: parsedName.suffix || "",
            lastName: parsedName.lastName || "",
            middleName: parsedName.middleName || "",
            fullName: parsedName.fullName || "",
          };
        });

        // Format the transformed data into a two-dimensional array of strings representing key-value pairs
        // const formattedData = transformedData.map((parsedName) => {
        //   const nameArray = [];
        //   for (const [key, value] of Object.entries(parsedName)) {
        //     nameArray.push(value);
        //   }
        //   return nameArray;
        // });

        const formattedData = transformedData.map((parsedName, index) => {
          const nameArray = [];
          for (const [key, value] of Object.entries(parsedName)) {
            if (index === 0) {
              // If it's the first array
              nameArray.push(`${key}: ${value}`); // Push both key and value
            } else {
              nameArray.push(value); // Push only the value for subsequent arrays
            }
          }
          return nameArray;
        });

        // Construct the result array
        // const result = [headers, ...formattedData];
        const result = formattedData;
        if (sub_status) {
          // Return all values if subscription is active
          return result;
        } else {
          if (result.length < 20) {
            // If user is on free trial and result records are less than 20, return the results without additional messages
            return result;
          } else {
            let sliceR = result.slice(0, 20);
            // If user is on free trial and result records are 20 or more, return top 20 values with additional messages
            sliceR.splice(
              20,
              0,
              ["• Only Top 20 records shown. Please subscribe to enjoy unlimited records lookups", "", "", "", "", ""],
              ["• To subscribe, click on upgrade button then click on number to words", "", "", "", "", ""],
              ["• for assistance contact us on smallpdfkenya@gmail.com", "", "", "", "", ""]
            );
            return sliceR;
          }
        }
      } else {
        // Return appropriate message if subscription is not active
        return [
          ["• Free runs already exhausted"],
          ["• To subscribe, click on upgrade on the side menu"],
          ["• for assistance contact us on smallpdfkenya@gmail.com"],
        ];
      }
    } catch (error) {
      throw error;
    }
  } else {
    return [["• Please login to use formula"]];
  }
}

export { SPLITNAMES };

CustomFunctions.associate("SPLITADDRESS", SPLITADDRESS);
CustomFunctions.associate("SPLITNAMES", SPLITNAMES);